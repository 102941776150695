import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Typography, Button } from '@material-ui/core';
import FundingStagesCell from './FundingStagesCell';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { red, yellow } from '@material-ui/core/colors';
import { stage2Text, stageSort } from '../utils/formatters';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '5px'
  },
}))(Tooltip);

const IssuedReturnsCell = (props) => {
    const { id, hasMultiplePartialRefunds, alreadyPaidStages, heldStages, numberOfPaymentStages,
         hasStagedFunding, mismatchedPaymentStages, multipleRefundsIncludingFullRefund } = props;
    let msgs = [];
    let icon = null;

    if (hasMultiplePartialRefunds) {
        msgs.push('Loan has multiple partial refunds');
        icon = <ErrorIcon style={{ color: yellow[700] }} />;
    }
    if (mismatchedPaymentStages.length > 0) {
        msgs.push(`Return and payment amount are not equal for: ${stageSort(mismatchedPaymentStages).map(stage2Text).join('\n')}`);
        icon = <ErrorIcon style={{ color: yellow[700] }} />;
    }
    if (multipleRefundsIncludingFullRefund) {
        msgs.push('Loan has multiple refunds including a full refund');
        icon = <WarningIcon style={{ color: red[500] }} />;
    }

    return msgs.length > 0 ?
        (
            <div style={{display:'flex'}}>
                <FundingStagesCell
                    hasStagedFunding={hasStagedFunding}
                    paidStages={alreadyPaidStages}
                    heldStages={heldStages}
                    numberOfPaymentStages={numberOfPaymentStages} />
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit" align="center"><b><u>Completed Stage Payments:</u></b></Typography>
                                <p>{stageSort(alreadyPaidStages.map(stage2Text)).join(', ')}</p>
                                { msgs.length > 0 &&
                                    <>
                                        <Typography color="inherit"><b><u>Alerts:</u></b></Typography>
                                        <ul>{msgs.map(msg => (<li key={id}>{msg}</li>))}</ul>
                                    </>
                                }
                            </React.Fragment>
                        }
                    >
                        <IconButton component="span">
                            {icon}
                        </IconButton>
                    </HtmlTooltip>
            </div>
        ) :
        (
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit"><b><u>Completed Stage Payments:</u></b></Typography>
                         <p>{stageSort(alreadyPaidStages.map(stage2Text)).join(', ')}</p>
                    </React.Fragment>
                }
            >
                <Button>
                    <FundingStagesCell
                        hasStagedFunding={hasStagedFunding}
                        paidStages={alreadyPaidStages}
                        heldStages={heldStages}
                        numberOfPaymentStages={numberOfPaymentStages}
                        />
                </Button>
            </HtmlTooltip>
        )
}

export default IssuedReturnsCell;